import {request} from "./request"
export default {
  // 动态新闻接口
  news: {
    getNews: function (option) {
      return request({
        url: '/wx/news.do',
        method: 'post',
        data: option
      })
    },
    getNewsFast: function (option) {
      return request({
        url: '/wx/news2.do',
        method: 'post',
        data: option
      })
    },
  },

  /**
   * 获取导师证书
   * @param options.search
   * @param options.page
   * @param options.limit
   */
  getTeachers(options) {
    return request({
      url: '/certificate/search.do',
      method: 'post',
      data: options
    })
  },

  /**
   * 添加一个配置
   * @param {string} options.version - 版本号
   * @param {string} options.content - 配置数据
   */
  setConfig(options) {
    return request({
      url: '/website/insert.do',
      data: options
    })
  },


  /**
   * 更新一个配置
   * @param {string} options.uid - 记录ID
   * @param {string} options.version - 版本
   * @param {string} options.content - 数据
   */
  updateConfig(options) {
    return request({
      url: '/website/update.do',
      data: options
    })
  },

  /**
   * 根据版本查询
   * @param {string} options.version - 版本
   */
  getConfig(options) {
    return request({
      url: '/website/query.do',
      data: options
    })
  },

  /**
   * 提交一个图片到OSS,返回ID
   * @param {string} options.data - 图片base64数据
   */
  uploadImg(options) {
    return request({
      url: '/website/image/upload.do',
      data: options
    })
  },

  /**
   * 删除一个OSS图片
   * @param {string} options.regionName - OSS地区
   * @param {string} options.bucketName - OSS模块
   * @param {string} options.objectName - OSS名称
   */
  deleteImg(options) {
    return request(({
      url: '/website/image/delete.do',
      data: options
    }))
  },
  /**
   * 登录以编辑首页
   * @param {string} options.username - 登录名
   * @param {string} options.password - 密码
   */
  login(options) {
    return request({
      url: '/login/account.do',
      data: options
    })
  }
}
