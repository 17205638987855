import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/components/layout/Layout'),
    children: [
      {
        path: '/',
        component: () => import('@/views/main/Main')
      },
      {
        path: '/college',
        component: () => import('@/views/college/College')
      },
      {
        path: '/curriculum',
        component: () => import('@/views/curriculum/Curriculum')
      },
      {
        path: '/competition',
        component: () => import('@/views/competition/Competition')
      },
      {
        path: '/about',
        component: () => import('@/views/about/About')
      },
      {
        path: '/winner',
        component: () => import('@/views/winner/Winner')
      },
      {
        path: '/curriculum-system',
        component: () => import('@/views/curriculum-system/CurriculumSystem')
      },
      {
        path: '/teaching-aids',
        component: () => import('@/views/teaching-aids/TeachingAids')
      },
      {
        path: '/search',
        component: () => import('@/views/search/Search')
      },
      {
        path: '/search-result',
        component: () => import('@/views/search/SearchResult')
      },
      {
        path: '/edit',
        component: () => import('@/views/edit/edit')
      },

      // 移动端路由
      {
        path: '/m',
        component: () => import('@/m-views/m-main/MMain'),
      },
      {
        path: '/m/college',
        component: () => import('@/m-views/m-college/MCollege'),
      },
      {
        path: '/m/competition',
        component: () => import('@/m-views/m-competition/MCompetition'),
      },
      {
        path: '/m/curriculum',
        component: () => import('@/m-views/m-curriculum/MCurriculum'),
      },
      {
        path: '/m/grow',
        component: () => import('@/m-views/m-grow/MGrow'),
      },
      {
        path: '/m/extra',
        component: () => import('@/m-views/m-extra/MExtra'),
      },
      {
        path: '/m/extranew',
        component: () => import('@/m-views/m-extra/MExtraNew'),
      },
      {
        path: '/m/category1',
        component: () => import('../m-views/m-category-1/MCategory1')
      },
      {
        path: '/m/category2',
        component: () => import('../m-views/m-category-2/MCategory2')
      },
      // 临时性页面
      {
        path: '/m/tmp/tutorclass',
        component: () => import('../m-views/m-tmp/tutor-class/MTmpTutorClass')
      },
      {
        path: '/m/tmp/tutorclass/2',
        component: () => import('../m-views/m-tmp/tutor-class/MTmpTutorClass2')
      },
    ]
  },
  // {
  //     // 320使用抽奖页面
  //   path: '/lottery',
  //   component: () => import('@/views/lottery/Lottery'),
  // },
  // {
  //     // 1. 由于静态化编译插件编译后,所有路由都会带有/, 导致/m和/m/被识别为不同的路由并进入404页面
  //     // 2. ios系统的某些微信内置浏览器有缓存问题, 导致页面更新无法及时反馈到用户端
  //     // 5.22错误记录:    由于忽视了/m/路由会进入404页面, 导致某些ios手机用微信打开手机端官网显示404, 而且删除404页面后仍然缓存, 继续显示404
  //     //      某些ios微信的缓存策略: 打开网页直接读取缓存, 完全不理会服务器, 于是定位到404页面, 手动刷新则会基于当前url刷新,
  //     //      定位到/404/, 由于此404页面已被删除, 因此显示nginx404, 无法显示正常的首页
  //     path: '/404',
  //     component: () => import('@/views/404/new404')
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 不建议使用vue-router的方式去设置静态构建的404页面, 如果一定要设置404, 应将404配置到nginx中
export default router
