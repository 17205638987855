import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 部分引入element, 仅在抽奖页面使用
// import {Button, Message, Input, MessageBox} from 'element-ui'
// Vue.use(Button)
// Vue.use(Input)
// Vue.prototype.$message = Message;
// Vue.prototype.$msgbox = MessageBox;
// Vue.prototype.$confirm = MessageBox.confirm;

// 网络接口
import axios from '@/network/index'
Vue.prototype.$axios = axios

new Vue({
  router,
  render: h => h(App),
  vuetify,
  mounted () {
    // 用于预渲染
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
