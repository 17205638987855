<template>
  <v-app id="app">
    <router-view/>
  </v-app>
</template>

<script>
  export default {
    name: 'app',
  }
</script>

<style lang="scss">
  // 引入全局样式
  @import "~@/assets/css/normalize.scss";

  // 制止vuetify的某些全局样式
  .v-application {
    font-family: pingFangSC-Regular, "Roboto", sans-serif!important;
    ul, ol {
      padding: 0!important;
    }
    a {
      color: inherit!important;
    }
  }
</style>
